<template>
  <l-main
    :showHeader="showHeader"
    :showFooter="showFooter"
    :containerStyles="containerStyles"
  >
    <template #header>
      <app-header/>
    </template>

    <div v-mutate="debounce(manageToTopButton, 300)">
      <router-view />
    </div>

    <template #footer>
      <app-footer
        :copyright="getFooterCopyright"
      />
    </template>

    <template #ui>
      <v-modal-store/>
      <v-snackbar-store/>
      <v-button
        rounded="circle"
        icon
        outlined
        color="default"
        size="lg"
        class="to-top-button d-md-none"
        v-if="showToTopButton"
        @click="scrollToTop"
      >
        <v-icon
          fill="dark"
          name="evraz-chevron-2"
          sprite-name="evraz"
        />
      </v-button>
    </template>
  </l-main>
</template>

<script>
import { LMain } from '@/layouts'
import { AppFooter, AppHeader } from './components/features'
import { VButton, VIcon, VModalStore, VSnackbarStore } from '@components/base'
import { mapGetters, mapState } from 'vuex'
import defaultLogo from '@assets/images/default/logo.svg'
import { DEFAULT_LOGO_KEY } from '@constants/components'
import { Frameable } from '@mixins'
import { Mutate } from '@directives'
import { debounce } from '@utils/helpers'

export default {
  name: 'App',

  directives: {
    Mutate
  },

  components: {
    AppFooter,
    AppHeader,
    LMain,
    VModalStore,
    VSnackbarStore,
    VIcon,
    VButton
  },

  mixins: [
    Frameable
  ],

  provide () {
    return {
      global: this.global
    }
  },

  data () {
    return {
      // todo перенести в Store
      global: {
        lk: {
          isLoggedIn: false,
          logout: () => this.lkLogout(),
          fetchAuth: () => this.lkFetchAuth()
        },
        user: {
          displayName: null
        },
        logo: {
          url: '',
          alt: '',
          setLogo: (options) => {
            this.setLogo(options)
          }
        },
        background: {
          url: '',
          setBackgroundUrl: (url) => {
            this.setBackgroundUrl(url)
          }
        }
      },
      showToTopButton: false
      // themePhoneMap: {
      //   'it-brand': '',
      //   gpn: ''
      // },
      // themeEmailMap: {
      //   'it-brand': 'it-brand@ecopsy.ru',
      //   gpn: 'gpn-opros@ecopsy.ru'
      // }
    }
  },

  mounted () {
    this.global.logo.setLogo()
  },

  computed: {
    ...mapState({
      currentTheme: state => state.theme.current,
      currentModes: state => state.mode.current
    }),

    ...mapGetters({
      isModeExistByName: 'mode/isModeExistByName'
    }),

    showFooter () {
      return !this.currentModes.includes('hide-footer')
    },

    showHeader () {
      return !this.currentModes.includes('hide-header')
    },

    getFooterCopyright () {
      const year = new Date().getFullYear()
      return `© ${this.$t('site.footer.companyName')}, 2012–${year}`
    },

    containerStyles () {
      return {
        ...(
          this.global.background.url
            ? { backgroundImage: `url(${this.global.background.url})` }
            : {}
        )
      }
    }
  },

  methods: {
    // todo перенести в Store
    lkLogout () {
      localStorage.removeItem('x-auth-token')
      this.global.lk.isLoggedIn = false
      this.$router.push(`${this.$root.VUE_APP_AUTH_PATH}`)
    },

    lkFetchAuth () {
      this.global.lk.isLoggedIn = !!localStorage['x-auth-token']
    },

    setLogo (options) {
      if (options?.url === DEFAULT_LOGO_KEY) {
        options.url = defaultLogo
      }
      Object.assign(this.global.logo, options)
    },

    scrollToTop () {
      if (this.isModeExistByName('frame')) {
        this.frameable_SendPostMessageToParent({
          type: 'scroll'
        })

        return
      }

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },

    manageToTopButton () {
      this.showToTopButton = document.body.scrollHeight > 1000
    },

    setBackgroundUrl (url) {
      this.global.background.url = url
    },

    debounce
  }
}
</script>

<style lang="scss">
// import default styles
@import "~@styles";

.to-top-button {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  background-color: rgba(255, 255, 255, .8);
}
</style>
