<template>
  <div class="container py-8">
    <div class="row flex-nowrap align-items-center">
      <div
        v-if="global.logo.url"
        class="col-auto"
      >
        <img
          :src="global.logo.url"
          :alt="global.logo.text"
          class="logo__img"
          draggable="false"
        />
      </div>

      <template v-if="showAddition">
        <div class="col-auto">
          <v-menu>
            <template #button>
              <v-button size="sm">
                {{ currentPage }}
              </v-button>
            </template>

            <v-list table>
              <v-list-item
                v-for="link in links"
                :key="link.url"
                :link="link.url"
                @click="reloadPage(link)"
              >
                <template #default>
                  {{ link.title }}
                </template>

                <template #end>
                <span>
                  <v-icon-svg
                    v-if="link.submenu"
                    class="svg-stroke-ui-main-1 rotate-270"
                    fill="none"
                    view-box="0 0 11 5"
                    title="svg-icon-chevron"
                    width="12"
                    height="12"
                  >
                  <svg-icon-chevron/>
                </v-icon-svg>
                </span>
                </template>

                <template #submenu v-if="link.submenu">
                  <v-list>
                    <v-list-item
                      v-for="subLink in link.submenu"
                      :key="subLink.url"
                      :link="`${$root.VUE_APP_GUIDE_PATH}${subLink.url}`"
                    >
                      {{ subLink.title }}
                    </v-list-item>
                  </v-list>
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="col-auto">
          <v-menu>
            <template #button>
              <v-button size="sm">
                Темы
              </v-button>
            </template>

            <v-list table>
              <v-list-item
                v-for="(theme, index) in themes"
                :key="index"
                :active="theme.current === theme.theme"
                clickable
                hoverable
                @click="setTheme(theme)"
              >
                <template #default>
                  {{ theme.title }}
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>

      <div class="col"></div>

      <div
        v-if="supportedLanguageShow"
        class="col-auto"
      >
        <v-language-select
          :language="language.current"
          :languages="supportedLanguageHash"
          @setLanguage="setLanguage"
        />
      </div>

      <div
        v-if="global.lk.isLoggedIn"
        class="col-auto"
      >
        <v-button
          size="sm"
          @click="global.lk.logout()"
        >
          Выход
        </v-button>
      </div>

      <div
        v-if="theme.current === 'evraz'"
        class="col text-nowrap text-right"
      >
        <p class="m-0 h2">ТОП&ndash;300</p>
        <p class="m-0 text-color-default">3&nbsp;поток</p>
      </div>

      <div
        v-if="showUserName"
        class="col-auto user-info"
      >
        <v-icon-svg
          class="mr-8 svg-fill-primary svg-stroke-primary"
          view-box="0 0 20 24"
          title="svg-icon-user"
          width="20"
          height="20"
        >
          <svg-icon-user/>
        </v-icon-svg>

        {{ global.user.displayName }}
      </div>
    </div>
  </div>
</template>

<script>
import SvgIconUser from '@components/icons/SvgIconUser'
import SvgIconChevron from '@components/icons/SvgIconChevron'
import { VButton, VIconSvg, VList, VListItem, VMenu } from '@components/base'
import { mapActions, mapGetters, mapState } from 'vuex'
import VLanguageSelect from '@components/base/VLanguageSelect/VLanguageSelect.vue'

export default {
  name: 'AppHeader',

  components: {
    VLanguageSelect,
    SvgIconChevron,
    SvgIconUser,
    VButton,
    VIconSvg,
    VList,
    VListItem,
    VMenu
  },

  inject: ['global'],

  data () {
    return {
      showAddition: this.$root.isDev,
      links: [
        {
          url: `${this.$root.VUE_APP_GUIDE_PATH}`,
          title: 'Вёрстка',
          reload: false,
          submenu: [
            { url: '/ui', title: 'UI' },

            { url: '/nlmk-cok-negative', title: 'НЛМК | ЦОК со штрафами' },
            { url: '/nlmk-cok', title: 'НЛМК | ЦОК' },
            { url: '/nlmk-competency-assessment', title: 'НЛМК | Оценка по компетенциям' },
            { url: '/nlmk-filling-in-the-pass', title: 'НЛМК | Заполнение пропуска' },
            { url: '/nlmk-graduated-scale', title: 'НЛМК | Градуированная шкала' },

            { url: '/evraz-lk', title: 'ЕВРАЗ | ЛК - Сотрудник' },
            { url: '/evraz-lk-mentor', title: 'ЕВРАЗ | ЛК - Наставник' },

            { url: '/wa-tasks', title: 'W@ | Задания' },
            { url: '/wa-test-list', title: 'W@ | Список тестов' },

            { url: '/360-stars-in-table', title: '360 | Звёздочки в таблице' },
            { url: '/360-stars', title: '360 | Звёздочки' },
            { url: '/360-choice-of-approval', title: '360 | Выбор утверждения' },
            { url: '/360-textarea-table', title: '360 | Таблица с текстарией' },
            { url: '/360-slider', title: '360 | Слайдер' },
            { url: '/360-chebur', title: '360 | Чебурашка' },
            { url: '/360-list', title: '360 | Список оцениваемых' },

            { url: '/agreement-2', title: 'Правила 2' },
            { url: '/tabs', title: 'Tabs' },
            { url: '/table', title: 'Таблица 1' },
            { url: '/dnd', title: 'drag and drop' },
            { url: '/textarea', title: 'textarea' },
            { url: '/inputs', title: 'Другие адаптивы' },
            { url: '/linear-choice', title: 'Вопрос с ползунком' },
            { url: '/linear-choice-2', title: 'Вопрос с ползунком. Пример 2' },
            { url: '/radio', title: 'Выбор одного в текстовом вопросе' },
            { url: '/checkbox', title: 'Множественный выбор' },
            { url: '/mostleast', title: 'Most-least' },
            { url: '/radio-table', title: 'Оценка всех вариантов ответа' },
            { url: '/instruction', title: 'Инструкция' },
            { url: '/check', title: 'Чек системы' },
            { url: '/agreement', title: 'Согласие' },
            { url: '/greetings', title: 'Приветствие' },
            { url: '/sections', title: 'Разделы' },
            { url: '/sections-2', title: 'Разделы. Пример 2' },
            { url: '/img-and-radio-card', title: 'Вопрос+плашки с ответами' },
            { url: '/radio-pictures', title: 'Вопросы с картинками' }
          ]
        },
        {
          reload: false,
          title: 'Авторизация',
          url: `${this.$root.VUE_APP_AUTH_PATH}`
        },
        {
          reload: true,
          title: 'all_questions',
          url: '/all_questions'
        }
      ],
      themes: [
        {
          theme: 'default',
          title: 'Основная',
          params: [
            {
                name: 'fio',
                visible: true
            },
            {
                name: 'email',
                value: 'custom@support.com',
                visible: true
            },
            {
                name: 'phone',
                value: '8-800-555-35-35',
                visible: true
            }
          ],
          cssStyle: ''
        },
        {
          theme: 'nlmk',
          title: 'НЛМК',
          params: [
            {
                name: 'fio',
                visible: true
            },
            {
                name: 'email',
                visible: true
            },
            {
                name: 'phone',
                visible: true
            }
          ],
          cssStyle: ''
        },
        {
          theme: 'evraz',
          title: 'EVRAZ',
          params: [
            {
                name: 'fio',
                visible: true
            },
            {
                name: 'email',
                visible: false
            },
            {
                name: 'phone',
                visible: false
            }
          ],
          cssStyle: ''
        },
        {
          theme: 'it-brand',
          title: 'IT-brand',
          params: [
            {
                name: 'fio',
                visible: true
            },
            {
                name: 'email',
                value: 'it-brand@ecopsy.ru',
                visible: true
            },
            {
                name: 'phone',
                value: '',
                visible: true
            }
          ],
          cssStyle: ''
        },
        {
          theme: 'fosagro',
          title: 'Фосагро',
          params: [
            {
                name: 'fio',
                visible: true
            },
            {
                name: 'email',
                visible: true
            },
            {
                name: 'phone',
                visible: true
            }
          ],
          cssStyle: ''
        }
      ],
      currentPage: 'Вёрстка'
    }
  },

  computed: {
    ...mapState({
      theme: state => state.theme,
      language: state => state.language,
      currentParams: state => state.theme.params
    }),

    ...mapGetters({
      supportedLanguageHash: 'language/supportedHash',
      supportedLanguageShow: 'language/supportedShow'
    }),

    showUserName () {
      return this.global.user.displayName &&
        this.currentParams.find(obj => obj.name === 'fio').visible
    }
  },

  methods: {
    ...mapActions({
      setTheme: 'theme/setTheme',
      setLanguage: 'language/setCurrent'
    }),
    reloadPage (link) {
      this.currentPage = link.title
    }
  }
}
</script>

<style lang="scss" scoped>
.lang-img-icon {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 1px solid var(--color-border)
}

.logo {
  display: flex;
  text-decoration: none;
  user-select: none;

  &__img {
    display: block;
    width: auto;
    height: 2rem;
    padding: 0;
    margin: 0
  }
}

.user-info {
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
